
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

const ImageUrlOrFileUploadProps = Vue.extend({
  props: {
    fileUploadButtonType: {
      type: String,
      default: "primary"
    },
    value: String,
    name: String,
    rules: {
      type: Object,
      default: () => {}
    }
  }
});

@Component
export default class ImageUrlOrFileUpload extends ImageUrlOrFileUploadProps {
  $refs!: {
    file: any;
  };

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit("input", value);
  }

  filesUploadChange(event: any) {
    this.$emit("fileUploadChange", {
      name: event.target.files[0].name,
      file: event.target.files[0]
    });
  }
}
